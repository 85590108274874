<template>
  <ListPage
    :article-list="articleList"
    :offset="+offset"
    :total="+total"
    :size="+size">
    <template v-slot:summery>
      <CategoryWidget
        :name="author.name"
        :username="author.username"
        :description="author.bio"
        :sub-type="author.subscribable && 'author'">
        <div
          v-if="author.email"
          class="contact-author btn btn-secondary">
          <a
            :href="`mailto:${author.email}`">
            <FontAwesomeIcon
              :icon="icons.faEnvelope"
              width="12px"/>
            {{ __('contact.$author', author.name.split(' ')[0]) }}
          </a>
        </div>
      </CategoryWidget>
    </template>
  </ListPage>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import ListPage from './page-segments/ListPage';

export default {
  name: 'author-page',
  components: {
    ListPage,
    FontAwesomeIcon,
  },
  data() {
    const { articleList, author, offset, total, size } = this.$store.state;
    return {
      author,
      articleList,
      offset,
      total,
      size,
      icons: {
        faEnvelope,
      },
    };
  },
};
</script>
